<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Empresas"
          buttonAddItemText="Nova Empresa"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="empresas"
              class=""
              :loading="loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon class="actions" @click="visualizar(item.uuid)">
                        mdi-eye
                      </v-icon>
                    </v-btn></template
                  >
                  <span>Visualizar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :loading="loadingEditar[`${item.id}`]"
                    >
                      <v-icon class="actions" @click="editar(item)">
                        mdi-pencil
                      </v-icon>
                    </v-btn></template
                  >
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on, attrs }"
                    v-if="item.id != 1 && item.id != 2"
                  >
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :loading="loadingDelete[`${item.id}`]"
                    >
                      <v-icon class="actions" @click="remover(item)">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Deletar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.nome`]="{ item }">
                <div
                  class="d-flex"
                  style="max-width: 300px; align-items: center"
                >
                  <v-img
                    class="ma-2"
                    height="60"
                    max-width="180"
                    :src="item.logotipo"
                    contain
                  ></v-img>
                  <span>
                    {{ item.nome }}
                  </span>
                </div>
              </template>
              <template v-slot:[`item.endereco`]="{ item }">
                <span>
                  {{
                    item.endereco == null
                      ? "Sem endereço"
                      : item.endereco.uf + "/" + item.endereco.cidade
                  }}
                </span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  value="A"
                  hide-details
                  :loading="loadingStatus[`${item.id}`]"
                ></v-switch>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span>
                  {{ item.created_at | dateFormatBr() }}
                </span>
              </template>
            </v-data-table>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <v-container fluid>
              <v-row class="ma-3" wrap>
                <!-- dados do cnpj ----------------------------->
                <v-col class="pt-0" cols="12" sm="12" md="12">
                  <h4 style="color: rgba(0, 0, 0, 0.54)">
                    Dados por consulta do CNPJ
                  </h4>
                </v-col>
                <!-- cnpj -->
                <v-col cols="12" sm="12" md="6">
                  <label>CNPJ</label>
                  <v-text-field
                    ref="cnpj"
                    placeholder="Ex: 00.000.000/0001-00"
                    required
                    outlined
                    dense
                    v-model="empresa.cnpj"
                    data-vv-as="CNPJ"
                    persistent-hint
                    :hint="errors.first('cnpj')"
                    :error="errors.collect('cnpj').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="cnpj"
                    name="cnpj"
                    v-mask="'##.###.###/####-##'"
                    @change="buscarDadosEmpresaPorCep()"
                  ></v-text-field>
                </v-col>
                <!-- razão social -->
                <v-col cols="12" sm="12" md="12">
                  <label>Razão Social</label>
                  <v-text-field
                    ref="razaoSocial"
                    placeholder="Escreva a razão social da sua empresa"
                    required
                    outlined
                    dense
                    v-model="empresa.razao_social"
                    data-vv-as="razão social"
                    persistent-hint
                    :hint="errors.first('razao_social')"
                    :error="
                      errors.collect('razao_social').length ? true : false
                    "
                    v-validate="'required|max:60'"
                    data-vv-validate-on="change"
                    id="razao_social"
                    name="razao_social"
                  ></v-text-field>
                </v-col>
                <!-- nome fantasia -->
                <v-col cols="12" sm="12" md="12">
                  <label>Nome Fantasia</label>
                  <v-text-field
                    ref="nomeFantasia"
                    placeholder="Escreva o nome fantasia da sua empresa"
                    required
                    outlined
                    dense
                    v-model="empresa.nome"
                    data-vv-as="nome fantasia"
                    persistent-hint
                    :hint="errors.first('nome')"
                    :error="errors.collect('nome').length ? true : false"
                    v-validate="'required|max:55'"
                    data-vv-validate-on="change"
                    id="nome"
                    name="nome"
                  ></v-text-field>
                </v-col>
                <!-- data de abertura -->
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    ref="menuCreate"
                    v-model="menuCreate"
                    :close-on-content-click="false"
                    :return-value.sync="empresa.abertura"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <label>Data de Abertura</label>
                      <v-text-field
                        v-model="empresa.abertura"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        data-vv-as="data de abertura"
                        persistent-hint
                        :hint="errors.first('abertura')"
                        :error="
                          errors.collect('abertura').length ? true : false
                        "
                        v-validate="'required'"
                        data-vv-validate-on="change"
                        id="abertura"
                        name="abertura"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="empresa.abertura"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuCreate = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuCreate.save(empresa.abertura)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- capital social -->
                <v-col cols="12" sm="12" md="6">
                  <label>Capital Social</label>
                  <v-text-field
                    ref="capitalSocial"
                    placeholder="Escreva o Capital Social da sua empresa"
                    required
                    outlined
                    dense
                    v-model="empresa.capital_social"
                    data-vv-as="capital social"
                    persistent-hint
                    :hint="errors.first('capital_social')"
                    :error="
                      errors.collect('capital_social').length ? true : false
                    "
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="capital_social"
                    name="capital_social"
                  ></v-text-field>
                </v-col>
                <!-- natureza juridica -->
                <v-col cols="12" sm="12" md="6">
                  <label>Natureza Jurídica</label>
                  <v-text-field
                    ref="naturezaJuridica"
                    placeholder="Escreva a Natureza Jurídica da sua empresa"
                    dense
                    outlined
                    v-model="empresa.natureza_juridica"
                    data-vv-as="natureza juridica"
                    persistent-hint
                    :hint="errors.first('natureza_juridica')"
                    :error="
                      errors.collect('natureza_juridica').length ? true : false
                    "
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="natureza_juridica"
                    name="natureza_juridica"
                  ></v-text-field>
                </v-col>
                <!-- tipo -->
                <v-col cols="12" sm="12" md="6">
                  <label>Tipo</label>
                  <v-select
                    ref="tipo"
                    placeholder="Selecione o Tipo da sua empresa"
                    :items="SelectItensTipo"
                    dense
                    outlined
                    v-model="empresa.tipo"
                    data-vv-as="tipo"
                    persistent-hint
                    :hint="errors.first('tipo')"
                    :error="errors.collect('tipo').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="tipo"
                    name="tipo"
                  ></v-select>
                </v-col>
                <!-- porte -->
                <v-col cols="12" sm="12" md="6">
                  <label>Porte</label>
                  <v-text-field
                    ref="porte"
                    placeholder="Escreva o porte da sua empresa"
                    required
                    outlined
                    dense
                    v-model="empresa.porte"
                    data-vv-as="porte"
                    persistent-hint
                    :hint="errors.first('porte')"
                    :error="errors.collect('porte').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="porte"
                    name="porte"
                  ></v-text-field>
                </v-col>
                <!-- situação -->
                <v-col cols="12" sm="12" md="6">
                  <label>Situação</label>
                  <v-text-field
                    ref="situacao"
                    placeholder="Escreva a situação da sua empresa"
                    required
                    outlined
                    dense
                    v-model="empresa.situacao"
                    data-vv-as="situacao"
                    persistent-hint
                    :hint="errors.first('situacao')"
                    :error="errors.collect('situacao').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="situacao"
                    name="situacao"
                  ></v-text-field>
                </v-col>
                <!-- cnaes -->
                <v-col cols="12" sm="12" md="12">
                  <div class="d-flex flex-column justify-start align-start">
                    <!-- BOTÃO DE ADICIONAR OPÇÃO -->
                    <div class="ml-3">
                      <v-btn
                        class="py-5"
                        dark
                        color="primary"
                        small
                        @click.stop="addItemCnae()"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                        <span>Adicione um CNAE</span>
                      </v-btn>
                    </div>

                    <v-container class="my-0 mr-3 pt-5 pb-1">
                      <v-card
                        elevation="6"
                        loader-height="7"
                        v-for="(cnae, index) in empresa.cnaes"
                        :key="cnae.codigo"
                        class="w-full mx-3 my-3 d-flex flex-row justify-space-between align-center"
                        min-height="3em"
                      >
                        <div
                          class="h-full d-flex flex-row justify-space-between align-center"
                          style="width: 100% !important"
                        >
                          <!-- POSIÇÃO -->
                          <div class="px-2">
                            <h4 class="text-center">
                              {{ index + 1 }}
                            </h4>
                          </div>

                          <v-card
                            elevation="0"
                            class="px-2"
                            style="width: 100% !important"
                          >
                            <v-row class="my-2">
                              <!-- CNAE DESCRIÇÃO -->
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field
                                  :ref="`descricao.${cnae.codigo}`"
                                  label="Descrição"
                                  placeholder="Descrição da atividade do CNAE"
                                  required
                                  outlined
                                  dense
                                  class="white"
                                  hide-details
                                  v-model="cnae.descricao"
                                  :data-vv-as="`descricao.${cnae.codigo}`"
                                  persistent-hint
                                  :hint="
                                    errors.first(`descricao.${cnae.codigo}`)
                                  "
                                  :error="
                                    errors.collect(`descricao.${cnae.codigo}`)
                                      .length
                                      ? true
                                      : false
                                  "
                                  v-validate="{ required: true }"
                                  data-vv-validate-on="blur|change"
                                  :id="`descricao.${cnae.codigo}`"
                                  :name="`descricao.${cnae.codigo}`"
                                ></v-text-field>
                              </v-col>
                              <!-- CNAE CODIGO -->
                              <v-col class="pb-0" cols="12" sm="12" md="6">
                                <v-text-field
                                  :ref="`codigo.${cnae.codigo}`"
                                  label="Código"
                                  placeholder="Código da atividade do CNAE"
                                  required
                                  outlined
                                  dense
                                  class="white"
                                  hide-details
                                  v-model="cnae.codigo"
                                  :data-vv-as="`codigo.${cnae.codigo}`"
                                  persistent-hint
                                  :hint="errors.first(`codigo.${cnae.codigo}`)"
                                  :error="
                                    errors.collect(`codigo.${cnae.codigo}`)
                                      .length
                                      ? true
                                      : false
                                  "
                                  v-validate="{ required: true }"
                                  data-vv-validate-on="blur|change"
                                  :id="`codigo.${cnae.codigo}`"
                                  :name="`codigo.${cnae.codigo}`"
                                ></v-text-field>
                              </v-col>
                              <!-- CNAE CATEGORIA -->
                              <v-col class="pb-0" cols="12" sm="12" md="6">
                                <v-select
                                  :ref="`categoria.${cnae.codigo}`"
                                  label="Categoria"
                                  placeholder="Categoria da atividade do CNAE"
                                  required
                                  outlined
                                  dense
                                  class="white"
                                  hide-details
                                  v-model="cnae.categoria"
                                  :items="['PRINCIPAL', 'SECUNDARIA']"
                                  :data-vv-as="`categoria.${cnae.codigo}`"
                                  persistent-hint
                                  :hint="
                                    errors.first(`categoria.${cnae.codigo}`)
                                  "
                                  :error="
                                    errors.collect(`categoria.${cnae.codigo}`)
                                      .length
                                      ? true
                                      : false
                                  "
                                  v-validate="{ required: true }"
                                  data-vv-validate-on="blur|change"
                                  :id="`categoria.${cnae.codigo}`"
                                  :name="`categoria.${cnae.codigo}`"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-card>
                          <!-- BOTÃO DE REMOVER CNAE -->
                          <div class="pr-2">
                            <v-tooltip top color="error" dark>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="py-5 bg-red"
                                  dark
                                  small
                                  @click.stop="removeCnae(cnae)"
                                >
                                  <v-icon dark> mdi-minus </v-icon>
                                </v-btn>
                              </template>
                              <span>Remover</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-card>
                    </v-container>
                  </div>
                </v-col>
                <!-- sócios -->
                <v-col cols="12" sm="12" md="12">
                  <div class="d-flex flex-column justify-start align-start">
                    <!-- BOTÃO DE ADICIONAR OPÇÃO -->
                    <div class="ml-3">
                      <v-btn
                        class="py-5"
                        dark
                        color="primary"
                        small
                        @click.stop="addItemSocio()"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                        <span>Adicione um Sócio</span>
                      </v-btn>
                    </div>

                    <v-container class="my-0 mr-3 pt-5 pb-1">
                      <v-card
                        elevation="6"
                        loader-height="7"
                        v-for="(socio, index) in empresa.socios"
                        :key="socio.nome"
                        class="w-full mx-3 my-3 d-flex flex-row justify-space-between align-center"
                        min-height="3em"
                      >
                        <div
                          class="h-full d-flex flex-row justify-space-between align-center"
                          style="width: 100% !important"
                        >
                          <!-- POSIÇÃO -->
                          <div class="px-2">
                            <h4 class="text-center">
                              {{ index + 1 }}
                            </h4>
                          </div>

                          <v-card
                            elevation="0"
                            class="px-2"
                            style="width: 100% !important"
                          >
                            <v-row class="my-2">
                              <!-- SOCIO NOME -->
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field
                                  :ref="`nome.${socio.nome}`"
                                  label="Nome"
                                  placeholder="Escreva o nome do Sócio"
                                  required
                                  outlined
                                  dense
                                  class="white"
                                  hide-details
                                  v-model="socio.nome"
                                  :data-vv-as="`nome.${socio.nome}`"
                                  persistent-hint
                                  :hint="errors.first(`nome.${socio.nome}`)"
                                  :error="
                                    errors.collect(`nome.${socio.nome}`).length
                                      ? true
                                      : false
                                  "
                                  v-validate="{ required: true }"
                                  data-vv-validate-on="blur|change"
                                  :id="`nome.${socio.nome}`"
                                  :name="`nome.${socio.nome}`"
                                ></v-text-field>
                              </v-col>
                              <!-- SOCIO QUAL -->
                              <v-col class="pb-0" cols="12" sm="12" md="12">
                                <v-text-field
                                  :ref="`qual.${socio.nome}`"
                                  label="Tipo"
                                  placeholder="Tipo de Sócio"
                                  required
                                  outlined
                                  dense
                                  class="white"
                                  hide-details
                                  v-model="socio.qual"
                                  :data-vv-as="`qual.${socio.nome}`"
                                  persistent-hint
                                  :hint="errors.first(`qual.${socio.nome}`)"
                                  :error="
                                    errors.collect(`qual.${socio.nome}`).length
                                      ? true
                                      : false
                                  "
                                  v-validate="{ required: true }"
                                  data-vv-validate-on="blur|change"
                                  :id="`qual.${socio.nome}`"
                                  :name="`qual.${socio.nome}`"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card>
                          <!-- BOTÃO DE REMOVER SOCIO -->
                          <div class="pr-2">
                            <v-tooltip top color="error" dark>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="py-5 bg-red"
                                  dark
                                  small
                                  @click.stop="removeSocio(socio)"
                                >
                                  <v-icon dark> mdi-minus </v-icon>
                                </v-btn>
                              </template>
                              <span>Remover</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-card>
                    </v-container>
                  </div>
                </v-col>
                <!-- dados de contato ----------------------------->
                <v-col class="pt-0" cols="12" sm="12" md="12">
                  <h4 style="color: rgba(0, 0, 0, 0.54)">Contato</h4>
                </v-col>
                <!-- email -->
                <v-col cols="12" sm="12" md="12">
                  <label>E-mail</label>
                  <v-text-field
                    ref="email"
                    placeholder="Escreva o E-mail da sua empresa"
                    required
                    outlined
                    dense
                    v-model="empresa.email"
                    data-vv-as="e-mail"
                    persistent-hint
                    :hint="errors.first('email')"
                    :error="errors.collect('email').length ? true : false"
                    v-validate="'required|email'"
                    data-vv-validate-on="change"
                    id="email"
                    name="email"
                  ></v-text-field>
                </v-col>
                <!-- telefone -->
                <v-col cols="12" sm="12" md="6">
                  <label>Telefone Fixo</label>
                  <v-text-field
                    ref="telefone"
                    placeholder="Escreva o Telefone Fixo de contato da sua empresa"
                    required
                    outlined
                    dense
                    v-model="empresa.telefone"
                    v-mask="'(##) ####-####'"
                  ></v-text-field>
                </v-col>
                <!-- celular -->
                <v-col cols="12" sm="12" md="6">
                  <label>Celular</label>
                  <v-text-field
                    ref="celular"
                    placeholder="Ex:(31) 9 9123-4567"
                    required
                    outlined
                    dense
                    v-model="empresa.celular"
                    data-vv-as="celular"
                    persistent-hint
                    :hint="errors.first('celular')"
                    :error="errors.collect('celular').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="celular"
                    name="celular"
                    v-mask="'(##) # ####-####'"
                  ></v-text-field>
                </v-col>
                <!-- dados de endereco ----------------------------->
                <v-col class="pt-0" cols="12" sm="12" md="12">
                  <h4 style="color: rgba(0, 0, 0, 0.54)">Endereco</h4>
                </v-col>
                <!-- viacep -->
                <v-col v-if="dialog" cols="12" sm="12" md="12">
                  <ViaCep
                    :enderecoAtual="empresa.endereco"
                    :atualiza="atualizaCEP"
                    @buscarendereco="receberEndereco"
                    @validar-cep="cepValido = $event"
                  />
                </v-col>
                <!-- dados de adicionais ----------------------------->
                <v-col class="pt-0" cols="12" sm="12" md="12">
                  <h4 style="color: rgba(0, 0, 0, 0.54)">Outros Dados</h4>
                </v-col>
                <!-- inscricao estadual -->
                <v-col cols="12" sm="12" md="6">
                  <label>Inscrição Estadual</label>
                  <v-text-field
                    placeholder="Escreva o Inscrição Estadual da sua empresa"
                    required
                    outlined
                    dense
                    v-model="empresa.inscricao_estadual"
                  ></v-text-field>
                </v-col>
                <!-- inscricao municipal -->
                <v-col cols="12" sm="12" md="6">
                  <label>Inscrição Municipal</label>
                  <v-text-field
                    placeholder="Escreva o Inscrição Municipal da sua empresa"
                    required
                    outlined
                    dense
                    v-model="empresa.inscricao_municipal"
                  ></v-text-field>
                </v-col>
                <!-- iss -->
                <v-col cols="12" sm="12" md="6">
                  <label>ISS</label>
                  <v-select
                    ref="iss"
                    placeholder="Selecione o Tipo da sua empresa"
                    :items="SelectItens"
                    dense
                    outlined
                    v-model="empresa.iss"
                    data-vv-as="ISS"
                    persistent-hint
                    :hint="errors.first('iss')"
                    :error="errors.collect('iss').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="iss"
                    name="iss"
                  ></v-select>
                </v-col>
                <!-- simples nacional -->
                <v-col cols="12" sm="12" md="6">
                  <label>Simples Nacional</label>
                  <v-select
                    ref="simples"
                    placeholder="Selecione o Tipo da sua empresa"
                    :items="SelectItens"
                    dense
                    outlined
                    v-model="empresa.simples"
                    data-vv-as="Simples"
                    persistent-hint
                    :hint="errors.first('simples')"
                    :error="errors.collect('simples').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="simples"
                    name="simples"
                  ></v-select>
                </v-col>
                <!-- descricao -->
                <v-col cols="12" sm="12" md="12">
                  <label>Descrição</label>
                  <v-textarea
                    ref="descricao"
                    outlined
                    dense
                    v-model="empresa.descricao"
                    data-vv-as="descrição"
                    persistent-hint
                    :hint="errors.first('descricao')"
                    :error="errors.collect('descricao').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="descricao"
                    name="descricao"
                  ></v-textarea>
                </v-col>
                <!-- dados do personalização ----------------------------->
                <v-col class="pt-0" cols="12" sm="12" md="12">
                  <h4 style="color: rgba(0, 0, 0, 0.54)">
                    Personalizar Logotipo e Banner
                  </h4>
                </v-col>
                <!-- logotipo -->
                <v-col v-if="dialog === true" cols="12" sm="12" md="6">
                  <label>Logotipo</label>
                  <input
                    name="logotipo"
                    type="file"
                    @change="previewImageLogotipo"
                    accept="image/*"
                  />
                </v-col>
                <!-- preview logotipo -->
                <v-col v-if="dialog === true" cols="12" sm="12" md="6">
                  <v-img :src="empresa.logotipo" height="auto" contain></v-img>
                </v-col>
                <!-- banner -->
                <v-col v-if="dialog === true" cols="12" sm="12" md="6">
                  <label>Banner</label>
                  <input
                    name="banner"
                    type="file"
                    @change="previewImageBanner"
                    accept="image/*"
                  />
                </v-col>
                <!-- preview banner -->
                <v-col v-if="dialog === true" cols="12" sm="12" md="6">
                  <v-img :src="empresa.banner" height="auto" contain></v-img>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Crud>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import ViaCep from "@/components/ViaCep.vue";
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";

export default {
  name: "PainelEmpresas",
  inject: ["$validator"],
  components: { ViaCep, Crud },
  data: () => ({
    logotipo: logo,
    nameForm: "Nova Empresa",
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    empresa: {
      id: "",
      uuid: "",
      razao_social: "",
      nome: "",
      logotipo: "",
      banner: "",
      cnpj: "",
      inscricao_estadual: "",
      inscricao_municipal: "",
      email: "",
      celular: "",
      telefone: "",
      iss: "",
      simples: "",
      descricao: "",
      cnae: "",
      cnaes: [],
      abertura: "",
      tipo: "",
      situacao: "",
      socio: "",
      socios: [],
      natureza_juridica: "",
      capital_social: "",
      porte: "",
      status: "",
      endereco: {
        cep: "",
        rua: "",
        numero: "",
        bairro: "",
        complemento: "",
        uf: "",
        cidade: "",
        categoria: "",
        status: "",
      },
    },
    imageData: "",
    SelectItens: ["SIM", "NÃO", "A VERIFICAR"],
    SelectItensTipo: ["MATRIZ", "FILIAL"],
    SelectItensStatus: [
      { text: "Ativo", value: "A" },
      { text: "Inativo", value: "I" },
    ],
    menuCreate: false,
    searchTextField: "",
    isUpdating: false,
    dialog: false,
    indexEdicao: -1,
    loadingTable: false,
    loadingEditar: [],
    loadingDelete: [],
    loadingStatus: [],
    headers: [
      { text: "Empresas", align: "start", value: "nome" },
      { text: "Localização", value: "endereco" },
      { text: "Data de Criação", value: "created_at" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    cepValido: false,
    atualizaCEP: false,
  }),

  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    empresas: {
      get() {
        return this.$store.getters["empresas/getEmpresas"];
      },
      set(value) {
        return value;
      },
    },
    loading: {
      get() {
        return this.$store.getters["empresas/getLoading"];
      },
      set(value) {
        return value;
      },
    },
  },
  created() {
    this.$store.dispatch("empresas/buscarEmpresas");
  },

  methods: {
    // LOGO
    previewImageLogotipo(evt) {
      let arquivo = evt.target.files || evt.dataTransfer.files;
      if (!arquivo.length || !arquivo[0] || arquivo[0].type === "text/plain")
        return;
      let reader = new FileReader();
      reader.onloadend = (e) => {
        this.empresa.logotipo = e.target.result;
      };
      reader.readAsDataURL(arquivo[0]);
    },
    previewImageBanner(evt) {
      let arquivo = evt.target.files || evt.dataTransfer.files;
      if (!arquivo.length || !arquivo[0] || arquivo[0].type === "text/plain")
        return;
      let reader = new FileReader();
      reader.onloadend = (e) => {
        this.empresa.banner = e.target.result;
      };
      reader.readAsDataURL(arquivo[0]);
    },
    visualizar(uuid) {
      this.$router.push({ name: "empresas-adm-info", params: { uuid: uuid } });
    },
    salvar() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.dialog = false;
          this.loadingTable = true;
          let url =
            this.indexEdicao === -1
              ? "/empresas"
              : "/empresas/" + this.empresa.uuid;
          let method = this.indexEdicao === -1 ? "POST" : "PUT";
          let sucesso =
            this.indexEdicao === -1
              ? "Empresa cadastrada com sucesso."
              : "Empresa atualizada com sucesso.";
          let erro =
            this.indexEdicao === -1
              ? "Erro ao salvar empresa."
              : "Erro ao atualizar empresa.";
          let empresaItem = this.empresa;
          if (empresaItem.celular !== null)
            empresaItem.celular = empresaItem.celular
              .toString()
              .replace(/\D+/g, "");
          if (empresaItem.cnpj !== null)
            empresaItem.cnpj = empresaItem.cnpj.toString().replace(/\D+/g, "");
          empresaItem._method = method;
          empresaItem.url = url;
          if (empresaItem.endereco != null)
            empresaItem.endereco.categoria = "EMPRESA";
          empresaItem.iss === "NÃO"
            ? (empresaItem.iss = "NAO")
            : empresaItem.iss === "A VERIFICAR"
            ? (empresaItem.iss = "PENDENTE")
            : (empresaItem.iss = "SIM");
          empresaItem.simples === "NÃO"
            ? (empresaItem.simples = "NAO")
            : empresaItem.simples === "A VERIFICAR"
            ? (empresaItem.simples = "PENDENTE")
            : (empresaItem.simples = "SIM");
          this.$store
            .dispatch("empresas/salvarEmpresa", empresaItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                this.indexEdicao === -1
                  ? this.empresas.push(response)
                  : Object.assign(
                      this.empresas[this.indexEdicao],
                      this.empresa
                    );
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.fechar();
            });
        } else {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Preencha todo formulário antes de salvar!",
            cor: "red",
          });
        }
      });
    },
    editar(item) {
      if (item.updated_at == null || item.updated_at == undefined) {
        this.loadingEditar[item.id] = true;
        this.$store
          .dispatch("empresas/buscarEmpresaSelecionada", item.uuid)
          .then((response) => {
            this.indexEdicao = this.empresas.indexOf(item);
            response.data.informacoes.iss === "NAO"
              ? (response.data.informacoes.iss = "NÃO")
              : response.data.informacoes.iss === "PENDENTE"
              ? (response.data.informacoes.iss = "A VERIFICAR")
              : (response.data.informacoes.iss = "SIM");
            response.data.informacoes.simples === "NAO"
              ? (response.data.informacoes.simples = "NÃO")
              : response.data.informacoes.simples === "PENDENTE"
              ? (response.data.informacoes.simples = "A VERIFICAR")
              : (response.data.informacoes.simples = "SIM");
            Object.assign(
              this.empresas[this.indexEdicao],
              response.data.informacoes
            );
            this.empresa = Object.assign({}, response.data.informacoes);
            this.nameForm = "Editar empresa: " + this.empresa.nome;
            this.dialog = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados do empresa selecionada.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingEditar[item.id] = false;
          });
      } else {
        this.nameForm = "Editar empresa: " + item.nome;
        this.indexEdicao = this.empresas.indexOf(item);
        this.empresa = Object.assign({}, item);
        this.dialog = true;
      }
    },
    fechar() {
      this.dialog = false;
      this.loadingTable = false;
      this.loadingDelete[item.id] = false;
      this.atualizaCEP = false;
      this.nameForm = "Nova empresa";
      this.empresa = {
        razao_social: "",
        nome: "",
        logotipo: "",
        banner: "",
        cnpj: "",
        inscricao_estadual: "",
        inscricao_municipal: "",
        email: "",
        celular: "",
        telefone: "",
        iss: "",
        simples: "",
        descricao: "",
        cnae: "",
        cnaes: [],
        abertura: "",
        tipo: "",
        situacao: "",
        socio: "",
        socios: [],
        natureza_juridica: "",
        capital_social: "",
        porte: "",
        status: "",
        endereco: {
          cep: "",
          rua: "",
          numero: "",
          bairro: "",
          complemento: "",
          uf: "",
          cidade: "",
          categoria: "",
          status: "",
        },
      };
    },
    remover(item) {
      this.$swal
        .fire({
          title: "Deleção",
          text: `Tem certeza que deseja deletar: ${item.nome} ?`,
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#1976d2",
          confirmButtonText: "Sim, quero deleta-lo!",
          cancelButtonColor: "#555",
          cancelButtonText: "Cancelar",
        })
        .then((response) => {
          if (response.isConfirmed) {
            this.loadingDelete[item.id] = true;
            const index = this.empresas.indexOf(item);

            this.loadingTable = true;
            this.$store
              .dispatch("empresas/deletarEmpresa", item.uuid)
              .then((data) => {
                if (data != "NAO_AUTORIZADO") {
                  this.empresas.splice(index, 1);

                  this.$swal.fire({
                    text: `Empresa ${item.nome} deletado com sucesso!`,
                    icon: "sucess",
                    showCloseButton: true,
                    confirmButtonColor: "#1976d2",
                    confirmButtonText: "Fechar",
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  text: `Não foi possível deletar empresa ${item.nome}, favor tente mais tarde!`,
                  icon: "sucess",
                  showCloseButton: true,
                  confirmButtonColor: "#1976d2",
                  confirmButtonText: "Fechar",
                });
              })
              .finally(() => {
                this.loadingTable = false;
                this.loadingDelete[item.id] = false;
                this.fechar();
              });
          }
        });
    },
    mudarStatus(item) {
      this.loadingStatus[item.id] = true;
      this.loadingTable = true;

      this.indexEdicao = this.empresas.indexOf(item);
      this.empresa = Object.assign({}, item);
      this.empresa.status =
        this.empresa.status == null ? "I" : this.empresa.status;

      let empresaItem = this.empresa;
      empresaItem._method = "PUT";
      empresaItem.url = "/empresas/" + this.empresa.uuid;
      this.$store
        .dispatch("empresas/salvarEmpresa", empresaItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            Object.assign(this.empresas[this.indexEdicao], this.empresa);
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Empresa atualizada com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar empresa.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingStatus[item.id] = false;
          this.loadingTable = false;
          this.indexEdicao = -1;
          this.empresa = Object.assign(
            {},
            {
              razao_social: "",
              nome: "",
              logotipo: "",
              banner: "",
              cnpj: "",
              inscricao_estadual: "",
              inscricao_municipal: "",
              email: "",
              celular: "",
              telefone: "",
              iss: "",
              simples: "",
              descricao: "",
              cnae: "",
              cnaes: [],
              abertura: "",
              tipo: "",
              situacao: "",
              socio: "",
              socios: [],
              natureza_juridica: "",
              capital_social: "",
              porte: "",
              status: "",
              endereco: {
                cep: "",
                rua: "",
                numero: "",
                bairro: "",
                complemento: "",
                uf: "",
                cidade: "",
                categoria: "",
                status: "",
              },
            }
          );
        });
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "A" || status == "Ativo") return "green";
      else return "red";
    },
    receberEndereco(endereco) {
      this.empresa.endereco = endereco;
    },
    buscarDadosEmpresaPorCep() {
      this.$store
        .dispatch(
          "empresas/buscarEmpresaPorCnpj",
          this.$options.filters.numbersOnly(this.empresa.cnpj)
        )
        .then((resp) => {
          if (resp.data) {
            //Empresa
            this.empresa.cnpj = resp.data.empresa.cnpj;
            this.empresa.razao_social = resp.data.empresa.nome;
            this.empresa.nome = resp.data.empresa.fantasia;
            this.empresa.abertura = resp.data.empresa.abertura;
            this.empresa.capital_social = resp.data.empresa.capital_social;
            this.empresa.natureza_juridica =
              resp.data.empresa.natureza_juridica;
            this.empresa.tipo = resp.data.empresa.tipo;
            this.empresa.porte = resp.data.empresa.porte;
            this.empresa.inscricao_municipal =
              resp.data.empresa.inscricao_municipal;
            this.empresa.inscricao_estadual =
              resp.data.empresa.inscricao_estadual;
            //Situação
            this.empresa.situacao = resp.data.situacao.situacao;
            //Sócio
            this.empresa.socio = resp.data.socios.qsa[0].nome; // Incluir tabela e salvar array de socios
            this.empresa.socios = [];
            resp.data.socios.qsa.forEach((socio) => {
              this.empresa.socios.push(socio);
            });
            //CNAE
            this.empresa.cnae =
              resp.data.atividades.atividade_principal[0].code; // Incluir tabela e salvar array de cnaes
            this.empresa.cnaes = [];
            resp.data.atividades.atividade_principal.forEach((cnae) => {
              let newCnae = Object.assign(
                {},
                { descricao: "", codigo: "", categoria: "" }
              );
              newCnae.descricao = cnae.text;
              newCnae.codigo = cnae.code;
              newCnae.categoria = "PRINCIPAL";
              this.empresa.cnaes.push(newCnae);
            });
            resp.data.atividades.atividades_secundarias.forEach((cnae) => {
              let newCnae = Object.assign(
                {},
                { descricao: "", codigo: "", categoria: "" }
              );
              newCnae.descricao = cnae.text;
              newCnae.codigo = cnae.code;
              newCnae.categoria = "SECUNDARIA";
              this.empresa.cnaes.push(newCnae);
            });
            //Dados que não vem na api do CNPJ
            // this.empresa.iss
            // this.empresa.simples
            // this.empresa.descricao
            //Contatos
            this.empresa.email = resp.data.contato.email;
            this.empresa.telefone = resp.data.contato.telefone;
            //Endereço
            this.empresa.endereco.cep = resp.data.endereco.cep;
            this.empresa.endereco.rua = resp.data.endereco.logradouro;
            this.empresa.endereco.numero = resp.data.endereco.numero;
            this.empresa.endereco.bairro = resp.data.endereco.bairro;
            this.empresa.endereco.complemento = resp.data.endereco.complemento;
            this.empresa.endereco.uf = resp.data.endereco.uf;
            this.empresa.endereco.cidade = resp.data.endereco.municipio;
            this.atualizaCEP = true;
          }
        })
        .catch((err) => {
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 5000,
            text: err.data.message,
          });
        })
        .finally(() => {
          this.loadingCnpj = false;
          this.disabledCnpj = false;
        });
    },
    //Repeater
    addItemCnae() {
      const newItem = {
        descricao: "",
        codigo: "",
        categoria: "",
      };
      this.empresa.cnaes.push(newItem);
    },
    removeCnae(item) {
      const index = this.empresa.cnaes.indexOf(item);
      this.empresa.cnaes.splice(index, 1);
    },
    addItemSocio() {
      const newItem = {
        nome: "",
        qual: "",
      };
      this.empresa.socios.push(newItem);
    },
    removeSocio(item) {
      const index = this.empresa.socios.indexOf(item);
      this.empresa.socios.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.bg-red {
  background-color: brown !important;
}
</style>

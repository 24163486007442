<template>
  <v-row>
    <!-- cep -->
    <v-col cols="12" sm="12" md="6">
      <v-text-field
        :class="corImput"
        :hide-details="hideDetailsImput"
        label="CEP"
        ref="cep"
        placeholder="Digite o CEP aqui"
        required
        outlined
        dense
        v-model="endereco.cep"
        @change="searchCep()"
        data-vv-as="cep"
        persistent-hint
        :hint="errors.first('cep')"
        :error="errors.collect('cep').length ? true : false"
        v-validate="'required'"
        data-vv-validate-on="change|blur"
        id="cep"
        name="cep"
        v-mask="'#####-###'"
      ></v-text-field>
    </v-col>
    <!-- rua -->
    <v-col cols="12" sm="12" md="10">
      <v-text-field
        :class="corImput"
        :hide-details="hideDetailsImput"
        label="Rua"
        ref="rua"
        placeholder="Rua"
        required
        outlined
        dense
        v-model="endereco.rua"
        @change="validarCep()"
        data-vv-as="rua"
        persistent-hint
        :hint="errors.first('rua')"
        :error="errors.collect('rua').length ? true : false"
        v-validate="'required|min:3'"
        data-vv-validate-on="change|blur"
        id="rua"
        name="rua"
      ></v-text-field>
    </v-col>
    <!-- número -->
    <v-col cols="12" sm="12" md="2">
      <v-text-field
        :class="corImput"
        :hide-details="hideDetailsImput"
        label="Nº"
        ref="numero"
        placeholder=""
        required
        outlined
        dense
        v-model="endereco.numero"
        @change="validarCep()"
        data-vv-as="numero"
        persistent-hint
        :hint="errors.first('numero')"
        :error="errors.collect('numero').length ? true : false"
        v-validate="'required'"
        data-vv-validate-on="change|blur"
        id="numero"
        name="numero"
      ></v-text-field>
    </v-col>
    <!-- bairro -->
    <v-col cols="12" sm="12" md="12">
      <v-text-field
        :class="corImput"
        :hide-details="hideDetailsImput"
        label="Bairro"
        ref="bairro"
        placeholder="Bairro"
        required
        outlined
        dense
        v-model="endereco.bairro"
        @change="validarCep()"
        data-vv-as="bairro"
        persistent-hint
        :hint="errors.first('bairro')"
        :error="errors.collect('bairro').length ? true : false"
        v-validate="'required|min:3'"
        data-vv-validate-on="change|blur"
        id="bairro"
        name="bairro"
      ></v-text-field>
    </v-col>
    <!-- complemento -->
    <v-col cols="12" sm="12" md="12">
      <v-text-field
        :class="corImput"
        :hide-details="hideDetailsImput"
        label="Complemento"
        ref="complemento"
        placeholder="Complemento"
        required
        outlined
        dense
        v-model="endereco.complemento"
        @change="validarCep()"
      ></v-text-field>
    </v-col>
    <!-- estado -->
    <v-col cols="12" sm="12" md="6">
      <v-text-field
        :class="corImput"
        :hide-details="hideDetailsImput"
        label="Estado"
        ref="uf"
        placeholder="UF"
        required
        outlined
        dense
        v-model="endereco.uf"
        @change="validarCep()"
        data-vv-as="uf"
        persistent-hint
        :hint="errors.first('uf')"
        :error="errors.collect('uf').length ? true : false"
        v-validate="'required'"
        data-vv-validate-on="change|blur"
        id="uf"
        name="uf"
      ></v-text-field>
    </v-col>
    <!-- cidade -->
    <v-col cols="12" sm="12" md="6">
      <v-text-field
        :class="corImput"
        :hide-details="hideDetailsImput"
        label="Cidade"
        ref="cidade"
        placeholder="Cidade"
        required
        outlined
        dense
        v-model="endereco.cidade"
        @change="validarCep()"
        data-vv-as="cidade"
        persistent-hint
        :hint="errors.first('cidade')"
        :error="errors.collect('cidade').length ? true : false"
        v-validate="'required|min:3'"
        data-vv-validate-on="change|blur"
        id="cidade"
        name="cidade"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "ViaCep",
  inject: ["$validator"],
  props: {
    corImput: {
      require: false,
      default: "",
    },
    hideDetailsImput: {
      require: false,
      default: false,
    },
    enderecoAtual: {
      require: false,
      type: Object,
      default: () => ({}),
    },
    tipo: {
      type: String,
      default: "",
    },
    atualiza: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      endereco: {},
    };
  },
  created() {
    this.endereco = Object.assign({}, this.enderecoAtual);
  },

  watch: {
    atualiza() {
      // method que atualiza campos do formulario de endereco caso o endereço seja trazido pela api do cnpj, no caso do crud de emrpesa
      this.endereco = Object.assign({}, this.enderecoAtual);
    },
  },

  methods: {
    searchCep() {
      if (this.endereco.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.endereco.cep}/json/`)
          .then((response) => {
            this.endereco.cep = response.data.cep;
            this.endereco.rua = response.data.logradouro;
            this.endereco.bairro = response.data.bairro;
            this.endereco.complemento = response.data.complemento;
            this.endereco.uf = response.data.uf;
            this.endereco.cidade = response.data.localidade;
            this.validarCep();
          })
          .catch((error) => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "CEP nãoenderecoLocal encontrado, tente outro.",
              cor: "red",
            });
          });
      }
    },
    validarCep() {
      this.$emit("buscarendereco", this.endereco);

      this.$validator.validateAll().then((response) => {
        if (response === true) {
          return this.$emit("validar-cep", true);
        }
      });

      return this.$emit("validar-cep", false);
    },
  },
};
</script>

<style></style>
